import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import StyledUL from "../components/styled-ul"


const Logo = styled.div`
  width: 104%;
  position: relative;
  left: -2%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  @media( min-width: 768px ) {
    margin-top: 9rem;
    margin-bottom: 15rem;
  }
`;

const Intro = styled.section`
  width: 100%;
  padding: 2rem 1.5rem;
  @media( min-width: 768px ) {
    width: 40%;
    margin-left: 50%;
  }

  a {
    color: #fff;
    text-decoration: none;
    transition: all .2s ease-in-out;
    &:visited {
      color: #fff;
    }

    &:hover {
      color: #bababa;
    }
  }
`;

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query IndexPage {
        allWordpressPost {
          edges {
            node {
              title
              excerpt
              content
              slug
              featured_media {
                localFile {
                  childImageSharp {
                    fluid(maxWidth:2800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        wordpressPage(slug: { eq: "home" }) {
          title
          date(formatString: "MMMM DD, YYYY")
          datetime: date(formatString: "YYYY-MM-DD hh:mm:ss")
          slug
          content
        }
      }
    `}
    render={ ({allWordpressPost, wordpressPage}) => {
      return (
        <Layout>
          <SEO title="Home" />
          <Logo>
            <svg viewBox="0 0 1920 355" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M84.1113 4.74599L172.947 255.334L261.308 4.74599H354.894V350.254H283.398V255.809L290.524 92.7841L197.175 350.254H148.244L55.1328 93.0214L62.2587 255.809V350.254H-9V4.74599H84.1113Z" fill="#FFB414"/>
            <path d="M701.211 185.331C701.211 219.344 695.194 249.164 683.159 274.793C671.124 300.421 653.864 320.196 631.378 334.118C609.05 348.039 583.397 355 554.418 355C525.757 355 500.183 348.118 477.697 334.355C455.211 320.592 437.792 300.975 425.44 275.505C413.089 249.876 406.834 220.451 406.675 187.229V170.144C406.675 136.131 412.772 106.231 424.965 80.4445C437.317 54.4998 454.656 34.6457 476.984 20.8824C499.47 6.96078 525.123 0 553.943 0C582.763 0 608.337 6.96078 630.665 20.8824C653.151 34.6457 670.491 54.4998 682.684 80.4445C695.035 106.231 701.211 136.052 701.211 169.906V185.331ZM629.002 169.669C629.002 133.441 622.51 105.915 609.525 87.0889C596.54 68.2631 578.013 58.8503 553.943 58.8503C530.032 58.8503 511.584 68.184 498.599 86.8516C485.614 105.361 479.043 132.571 478.884 168.483V185.331C478.884 220.609 485.377 247.978 498.362 267.436C511.346 286.895 530.032 296.624 554.418 296.624C578.33 296.624 596.699 287.291 609.525 268.623C622.352 249.797 628.844 222.429 629.002 186.517V169.669Z" fill="#FFB414"/>
            <path d="M1034.23 350.254H962.968L824.251 122.921V350.254H752.992V4.74599H824.251L963.206 232.553V4.74599H1034.23V350.254Z" fill="#FFB414"/>
            <path d="M1204.77 211.671L1167.72 251.537V350.254H1096.46V4.74599H1167.72V161.364L1199.07 118.412L1287.19 4.74599H1374.84L1252.04 158.279L1378.41 350.254H1293.61L1204.77 211.671Z" fill="#FFB414"/>
            <path d="M1613.32 200.518H1476.51V293.065H1637.07V350.254H1405.25V4.74599H1636.6V62.4098H1476.51V144.753H1613.32V200.518Z" fill="#FFB414"/>
            <path d="M1798.12 160.414L1870.09 4.74599H1948L1834.46 224.96V350.254H1762.02V224.96L1648.48 4.74599H1726.62L1798.12 160.414Z" fill="#FFB414"/>
            </svg>
          </Logo>
          <Intro>
            <div dangerouslySetInnerHTML={{
                __html: wordpressPage.content,
              }}
            />
          </Intro>


          <StyledUL posts={allWordpressPost.edges} />
        </Layout>
    )}
    }
  />

)

export default IndexPage
